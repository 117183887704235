<template>
  <div>
    <div class="promocao-adicionar">
      <div class="conteudo-interno">
        <div class="nova-comunicacao-container">
          <h1>Adicionar Performance</h1>
          <FormularioPerformance @salvar="salvar($event)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { cadastrarPromocoes } from "@/services/promocoes";
const FormularioPerformance = () => import("@/components/elementos/paginas/FormularioPerformance.vue");
export default {
  name: "PerformanceAdicionar",
  components: {
    FormularioPerformance,
  },
};
</script>

<style lang="scss">
.promocao-adicionar {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
</style>
